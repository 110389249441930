<template>
  <!-- <div class="text-center"> -->
  <v-dialog v-model="dialog" @click:outside="clickOutside" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        fab
        dark
        color="hsla(230, 100%, 50%, 0.8)"
        @click="isScanStarted = true"
      >
        <v-icon dark color="white">
          mdi-qrcode-scan
        </v-icon>
      </v-btn>
    </template>
    <v-card style="position:relative;background:black;">
      <div style="position:relative; height:85vh;padding: 0 10px;">
        <div
          style="position:relative; top:5px; left:0;width:100%;padding:5px 0;margin-bottom:20px;"
        >
          <v-btn
            style="position:absolute; top:0; left:5px;"
            icon
            small
            color="white"
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h6 style="text-align:center;margin:0;color:white;">
            Scan QR Code / Barcode
          </h6>
        </div>
        <div
          style="margin:auto; max-width:640px;height:75vh; background:white;"
        >
          <div style="min-height:50%;background:rgba(0,0,0,0.2);">
            <StreamBarcodeReader
              v-if="isScanStarted"
              @decode="(a, b, c) => onDecode(a, b, c)"
              @loaded="() => onLoaded()"
            ></StreamBarcodeReader>
          </div>
          <div style="padding: 5px 15px;margin-top:25px;">
            <div style="text-align:center;">
              <!-- @click="linkOpen(text)" -->
              <v-btn
                elevation="2"
                small
                max-width="100%"
                outlined
                color="indigo"
                class="indigo--text"
                rounded
              >
                <span class="text-truncate" style="max-width:200px">{{
                  text || '-'
                }}</span>
                <v-icon right>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
  <!-- </div> -->
</template>
<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
  name: 'Asset-Scanner',
  props: ['page'],
  components: {
    StreamBarcodeReader
  },
  data() {
    return {
      dialog: false,

      text: '',
      id: null,
      isScanStarted: false
    }
  },
  methods: {
    onDecode(a, b, c) {
      // const self = this
      // setTimeout(() => {
      if (this.text !== a) {
        this.text = a
        this.linkOpen(a)
        // if (this.id) clearTimeout(this.id)
        // this.id = setTimeout(() => {
        //   if (this.text === a) {
        //     this.text = ''
        //   }
        // }, 5000)
      }
      // }, 1000)
    },
    onLoaded() {
      console.log('load')
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clickOutside() {
      console.log('outside')
    },
    close() {
      this.text = ''
      this.isScanStarted = false
      this.dialog = false
    },
    linkOpen(item) {
      // this.text = ''
      // this.isScanStarted = false
      // this.dialog = false
      this.close()

      console.log('>>> QRCODE INSIDE: ', item)
      // movement form
      if (item.includes('MOVEMENT_FORM')) {
        const id = item.slice(item.lastIndexOf('|') + 1)
        // if (this.$route.params.id !== id) {
        //   this.$router.push(`/asset-management/detail-penerimaan/${id}`)
        //   this.$emit('loadPage')
        // }
        if (this.$route.path.includes('/asset-management/detail-penerimaan/')) {
          if (this.$route.params.id !== id) {
            this.$router.push(`/asset-management/detail-penerimaan/${id}`)
            this.$emit('loadPage')
          }
        } else {
          this.$router.push(`/asset-management/detail-penerimaan/${id}`)
        }
      }

      // signature
      if (item.includes('VALIDATE_SIGNATURE')) {
        const id = item.slice(item.indexOf('|') + 1)
        if (this.$route.params.id === id) {
          this.$emit('loadPage')
          // const routeData = this.$router.resolve(`/detail-persetujuan/${id}`)
          // console.log(routeData)
          // window.open(routeData.href, '_blank')
        } else {
          this.$router.push(`/detail-persetujuan/${id}`)
          this.$emit('loadPage')
        }
      }

      // Lot detail & movement process
      // item.includes('http') || item.includes('https') before update
      if (
        item.includes('LOT_DETAIL') ||
        item.includes('http') ||
        item.includes('https')
      ) {
        const lot_no = item.slice(item.lastIndexOf('/') + 1)
        console.log(lot_no)
        if (this.page !== undefined) {
          this.$emit('lotProcess', lot_no)
        } else {
          if (
            this.$route.path.includes(
              '/asset-management/aset-kepemilikan/lot/detail/'
            )
          ) {
            if (this.$route.params.id !== lot_no) {
              this.$router.push(
                `/asset-management/aset-kepemilikan/lot/detail/${lot_no}`
              )
              this.$emit('loadPage')
            }
          } else {
            if (lot_no !== '') {
              this.$router.push(
                `/asset-management/aset-kepemilikan/lot/detail/${lot_no}`
              )
            } else {
              this.showMsgDialog('warning', 'INVALID QRCODE', false)
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped></style>
