import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  state: {
    hr: buildType.apiURL('hr'),
    proc: buildType.apiURL('proc'),
    eSanqua: buildType.apiURL('esanqua'),
    oAuth: buildType.apiURL('oauth'),
    production:
      process.env.VUE_APP_BUILD_TYPE === 'development' ||
      process.env.VUE_APP_BUILD_TYPE === 'domain' ||
      process.env.VUE_APP_BUILD_TYPE === 'preproduction',
    month: [
      { id: 1, name: 'JAN' },
      { id: 2, name: 'FEB' },
      { id: 3, name: 'MAR' },
      { id: 4, name: 'APR' },
      { id: 5, name: 'MAY' },
      { id: 6, name: 'JUN' },
      { id: 7, name: 'JUL' },
      { id: 8, name: 'AGS' },
      { id: 9, name: 'SEP' },
      { id: 10, name: 'OKT' },
      { id: 11, name: 'NOV' },
      { id: 12, name: 'DES' }
    ],
    productCategory: [],
    isLoading: false,
    tabs: [],
    permission: {},

    department: [],
    unit: [],
    globalUnit: [],
    docStatus: [],
    plant: [],
    company: [],
    location: [],
    archive: [],
    employee: [],
    docCategory: [],
    docSubCategory: [],
    documentName: '',
    documentId: null,
    documentQty: 0,
    documentUom: null,
    documentType: '',
    linkAddress: process.env.VUE_APP_BUILD_TYPE,
    drawer: []
  },
  mutations: {
    setDrawer(state, payload) {
      // if (
      //   state.drawer.find(({ title }) => title === payload.title) !== undefined
      // ) {
      //   state.drawer.push(payload)
      // }
      state.drawer = payload
    },
    setPermission(state, payload) {
      state.permission = payload
    },
    setTabs(state, payload) {
      state.tabs = payload
    },
    setDropdownProductCategory(state, payload) {
      state.productCategory = payload.data
    },
    setIsLoading(state, payload) {
      state.isLoading = payload
    },

    setLoaningDocumentName(state, payload) {
      state.documentName = payload[0]
      state.documentId = payload[1]
      state.documentQty = payload[3]
      state.documentUom = payload[4]
      if (payload[2] !== null) {
        if (payload[2] === 1) {
          state.documentType = 'admin'
        }
        if (payload[2] === 2) {
          state.documentType = 'public_user'
        }
      } else {
        state.documentType = ''
      }
    },
    setDropdownDepartment(state, payload) {
      console.log(payload)
      state.department = payload.data
    },
    setDropdownCompany(state, payload) {
      state.company = payload.data
    },
    setDropdownUnit(state, payload) {
      state.unit = payload.data
    },
    setDropdownGlobalUnit(state, payload) {
      state.globalUnit = payload.data
    },
    setDropdownDocStatus(state, payload) {
      state.docStatus = payload.data
    },
    setDropdownPlant(state, payload) {
      const arrData = payload.data
      if (payload.status_code === '00') {
        state.plant = arrData
        state.plant.unshift({ id: '', name: 'All' })
      } else {
        state.plant = []
      }
    },
    setDropdownArchive(state, payload) {
      state.archive = payload.data
    },
    setDropdownEmployee(state, payload) {
      state.employee = payload.data
    },
    setDropdownDocCategory(state, payload) {
      state.docCategory = payload.data
    },
    setDropdownDocSubCategory(state, payload) {
      state.docSubCategory = payload.data
    },
    setDropdownLocation(state, payload) {
      state.location = payload.data
    }
  },
  actions: {
    menu_access(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.oAuth}user_level_access/get_access?menu_id=${payload[0]}&level_id=${payload[1]}`
          )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getMenuId(context, payload) {
      return new Promise((resolve, reject) => {
        let url = `${context.state.oAuth}menu/drop_down?keyword=${payload.keyword}&order_by=id&order_type=asc`
        const filter = []
        if (payload.path !== null) {
          filter.push(`{"path": "${payload.path}"}`)
        }
        url = url + `&filter=[${filter}]`

        axios
          .get(url)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    dropdownProductCategory(context, payload) {
      const keyword = payload === undefined ? '' : payload
      axios
        .get(
          `${context.state.proc}master/product_category/drop_down?keyword=${keyword}`
        )
        .then((response) => {
          context.commit('setDropdownProductCategory', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    dropdownDepartment(context) {
      // if (context.state.department.length === 0) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.hr}master/department/dropdown`)
          .then((response) => {
            resolve(response.data)
            context.commit('setDropdownDepartment', response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
      // }
    },
    dropdownCompany(context) {
      // if (context.state.department.length === 0) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.eSanqua}master/universal/plant/dropdown`)
          .then((response) => {
            resolve(response.data)
            context.commit('setDropdownCompany', response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
      // }
    },
    dropdownUnit(context) {
      if (context.state.unit.length === 0) {
        axios
          .get(`${context.state.proc}master/unit/drop_down?keyword=`)
          .then((response) => {
            context.commit('setDropdownUnit', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    dropdownGlobalUnit(context) {
      // if (context.state.unit.length === 0) {
      axios
        .get(`${context.state.eSanqua}master/universal/unit/dropdown`)
        .then((response) => {
          context.commit('setDropdownGlobalUnit', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      // }
    },
    dropdownDocStatus(context) {
      if (context.state.docStatus.length === 0) {
        axios
          .get(`${context.state.eSanqua}archive_document_status/drop_down`)
          .then((response) => {
            context.commit('setDropdownDocStatus', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    dropdownDocCategory(context) {
      // if (context.state.docCategory.length === 0) {
      axios
        .get(`${context.state.eSanqua}master/archive/documentcategory/dropdown`)
        .then((response) => {
          context.commit('setDropdownDocCategory', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      // }
    },
    dropdownDocSubCategory(context, payload) {
      console.log(payload)
      // if (context.state.docCategory.length === 0) {
      let filter = ''
      if (payload === null || payload === '') {
        filter = ''
      } else {
        filter = `?filter=[{"category_id":${payload}}]`
      }
      axios
        .get(
          `${context.state.eSanqua}master/archive/documentsubcategory/dropdown${filter}`
        )
        .then((response) => {
          context.commit('setDropdownDocSubCategory', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      // }
    },
    dropdownPlant(context) {
      console.log('dropdown company>>>>', context.state.plant)
      if (context.state.plant.length === 0) {
        axios
          // .get(`${context.state.hr}master/company/list`)
          .get(`${context.state.eSanqua}master/universal/plant/dropdown`)
          .then((response) => {
            context.commit('setDropdownPlant', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    dropdownArchive(context, payload) {
      // if (context.state.archive.length === 0) {
      axios
        .get(
          `${context.state.eSanqua}archive_document/drop_down?keyword=${context.state.documentName}&mode=${payload}`
        )
        .then((response) => {
          context.commit('setDropdownArchive', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      // }
    },
    dropdownEmployee(context, payload) {
      // if (context.state.employee.length === 0) {
      axios
        .get(`${context.state.hr}employee/list?keyword=${payload}&company_id=`)
        .then((response) => {
          context.commit('setDropdownEmployee', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      // }
    },
    dropdownLocation(context, payload) {
      // if (context.state.department.length === 0) {
      axios
        .get(
          `${context.state.eSanqua}master/universal/location/dropdown?filter=[{"plant_id":${payload}}]`
        )
        .then((response) => {
          context.commit('setDropdownLocation', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      // }
    }
  },
  getters: {
    getDrawer(state) {
      return state.drawer
    },
    getMonth(state) {
      return state.month
    },
    getDropdownProductCategory(state) {
      return state.productCategory
    },
    getIsLoading(state) {
      return state.isLoading
    },
    getProductImageLink(state) {
      if (state.production) {
        return 'https://e-sanqua.sanquawater.co.id/image/ecatalogue/'
      } else {
        // return 'https://e-sanqua.sanquawater.co.id/image/ecatalogue/'
        return 'http://10.10.20.8:8020/'
      }
    },
    getTabs(state) {
      return state.tabs
    },
    getPermission(state) {
      return state.permission
    },

    getDropdownDepartment(state) {
      return state.department
    },
    getDropdownCompany(state) {
      return state.company
    },
    getDropdownUnit(state) {
      return state.unit
    },
    getDropdownGlobalUnit(state) {
      return state.globalUnit
    },
    getDropdownDocStatus(state) {
      return state.docStatus
    },
    getDropdownDocCategory(state) {
      return state.docCategory
    },
    getDropdownDocSubCategory(state) {
      return state.docSubCategory
    },
    getDropdownPlant(state) {
      return state.plant
    },
    getDropdownArchive(state) {
      return state.archive
    },
    getDropdownEmployee(state) {
      return state.employee
    },
    getDocId(state) {
      return {
        id: state.documentId,
        name: state.documentName,
        type: state.documentType,
        qty: state.documentQty,
        uom: state.documentUom
      }
    },
    getDropdownLocation(state) {
      return state.location
    },
    getLinkAddress(state) {
      switch (state.linkAddress) {
        case 'production':
          return 'http://10.10.20.8:8082'
        case 'domain':
          return 'https://e-sanqua.sanquawater.co.id'
        case 'dev_domain':
          return 'http://10.10.20.8:7351'
        case 'development':
          return 'https://dev-esanqua.sanquawater.co.id'
        case 'preproduction':
          return 'https://e-sanqua.sanquawater.co.id'
        default:
          return 'undetected_domain'
      }
    }
  }
}
