<template>
  <v-list>
    <v-list-item>
      <v-list-item-avatar>
        <v-img src="../../assets/image/account.png"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{
          getUserProfile !== null ? getUserProfile.username : 'Administrator'
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item-group active-class="deep-purple--text text--accent-4">
        <v-list-item
          class="drawer-li-item"
          v-for="item in getDrawer"
          :key="item.title"
          link
        >
          <router-link class="drawer-router-link" :to="item.path">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="drawer-li-title">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-divider></v-divider>
    <v-list-item class="drawer-li-item" link @click="signOut">
      <v-list-item-icon>
        <v-icon color="red" style="font-weight:bold;">mdi-logout</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title
          class="drawer-li-title"
          style="font-size:14px; font-weight:bold; color:red;"
        >
          Logout
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- <div class="pa-2">
      <v-btn class="pa-2-logout" block @click="signOut" style="width: 50%">
        <v-icon class="drawer-logout-icon">mdi-power</v-icon>
        <div class="drawer-logout-text">Sign out</div>
      </v-btn>
    </div> -->
  </v-list>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Drawer',
  props: [],
  data: () => ({
    items: []
  }),
  computed: {
    ...mapGetters(['getUserProfile', 'getDrawer'])
  },
  created() {
    // this.items = [
    //   {
    //     title: 'Open Profile',
    //     path: `/hr/employee/detail/${this.getUserProfile.employee_id}`,
    //     icon: 'mdi-account-cog'
    //   },
    //   {
    //     title: 'Change password',
    //     path: '/change-password',
    //     icon: 'mdi-key-change'
    //   }
    // ]
  },
  methods: {
    ...mapActions(['logout']),
    ...mapMutations(['setCatalogueDetail', 'setTabs']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },

    signOut() {
      this.showMsgDialog('question', 'Sign out now', true).then(res => {
        if (res.isConfirmed) {
          this.logout()
            .then(response => {
              console.log(response)
              // this.setTabs([]);
            })
            .catch(error => {
              this.showMsgDialog(
                'error',
                error
                  ? 'Something went wrong please contact an admin'
                  : 'Something went wrong please contact an admin',
                false
              )
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pa-2 {
  width: 100%;
  position: absolute;
  bottom: 10px;
  border-top: 1px solid #e0e0e0;

  .pa-2-logout {
    border: 1px solid rgba(255, 37, 73, 0.568);
    &:hover {
      background: rgba(255, 37, 73, 0.568);
    }

    .drawer-logout-text {
      margin-right: 10px;
      color: rgba(255, 37, 73, 0.568);
    }
    .drawer-logout-icon {
      color: rgba(255, 37, 73, 0.568);
    }
    &:hover .drawer-logout-text {
      color: white;
    }
    &:hover .drawer-logout-icon {
      color: white;
    }
  }
}

.drawer-li-item {
  &:hover {
    background: rgba(201, 201, 201, 0.685);
    color: white;
  }
  .drawer-router-link {
    width: 100%;
    display: flex;
    text-decoration: none;
    // &:hover {
    //   background: deepskyblue;
    // }
  }
  .drawer-li-title {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
