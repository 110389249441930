import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  state: {
    procAPI: buildType.apiURL('proc'),
    uploadAPI: buildType.apiURL('upload2'),
    productCompareStatus: false,

    param: {
      src: '',
      id: null
    },

    compareItems: [],
    catalogueList: [],
    catalogueDetail: {},
    totalCatalogue: 0,
    vendorDetail: null,

    isCompareLoading: false,
    specForCompare: [],
    otherSupplier: [],
    spesificationDetail: [],
    spesification_type: null,
    coordinate: {}
  },
  mutations: {
    setSearchCatalogue(state, payload) {
      state.param = {
        src: payload[0],
        id: payload[1]
      }
    },
    setCompareLoading(state, payload) {
      state.isCompareLoading = payload
    },
    productCompare(state, payload) {
      state.productCompareStatus = payload
    },
    setProductCompare(state, payload) {
      state.compareItems = payload
    },
    setCatalogueList(state, payload) {
      const response = payload.data
      if (response !== undefined) {
        state.totalCatalogue = payload.total_record
        state.catalogueList = response
      } else {
        state.totalCatalogue = 1
        state.catalogueList = []
      }
    },
    setCatalogueDetail(state, payload) {
      state.catalogueDetail = payload
    },
    setOtherSupplierCatalogue(state, payload) {
      state.coordinate = payload.data.vendor_location
      state.otherSupplier = []
      const response = payload.data.other_vendor
      if (response !== undefined) {
        for (let i = 0; i < response.length; i++) {
          state.otherSupplier.push({
            no: 1 + i,
            id: response[i].id,
            uom: response[i].uom_name,
            price:
              response[i].last_price !== null ? response[i].last_price : '-',
            vendor: response[i].vendor
          })
        }
      } else {
        state.otherSupplier = []
      }
    },
    setSpesificationDetail(state, payload) {
      const response = payload.data
      state.spesificationDetail = response
    },
    setSpesificationType(state, payload) {
      const response = payload.data
      state.spesification_type = response.catalogue_type
    },
    setVendorDetail(state, payload) {
      if (payload.status_code === '00') {
        state.vendorDetail = payload.data
      }
    },
    setSpesificationForCompare(state, payload) {
      state.specForCompare = payload
    }
  },
  actions: {
    compareProduct(context, payload) {
      setTimeout(() => {
        context.commit('setCompareLoading', true)
        context.commit('setProductCompare', payload)
        const arr = []
        for (let i = 0; i < payload.length; i++) {
          axios
            .get(
              `${context.state.procAPI}vendor/catalogue_spesification/list?offset=0&limit=all&keyword=&vendor_catalogue_id=${payload[i].id}`
            )
            .then(res => {
              arr.push(res.data.data)
            })
        }
        setTimeout(() => {
          context.commit('setSpesificationForCompare', arr)
        }, 1000)
      }, 3000)
    },
    specForCompare(context, payload) {
      const arr = []
      for (let i = 0; i < payload.length; i++) {
        axios
          .get(
            `${context.state.procAPI}vendor/catalogue_spesification/list?offset=0&limit=all&keyword=&vendor_catalogue_id=${payload}`
          )
          .then(res => {
            arr.push(res.data.data)
          })
      }
      setTimeout(() => {
        context.commit('setSpesificationForCompare', arr)
      }, 1000)
      // })
    },
    catalogueList(context, payload) {
      // const url =
      // if (payload[5]) {
      //   url + '&is_asset=1'
      // }
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.procAPI}vendor/catalogue/list?keyword=${
              payload[1]
            }&offset=${payload[2]}&limit=${payload[3]}&vendor_id=${
              payload[0]
            }&category_id=${payload[4]}${payload[5] ? '&is_asset=1' : ''}`
          )
          .then(response => {
            context.commit('setCatalogueList', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    otherSupplierCatalogue(context, payload) {
      const catalogueId = context.state.catalogueDetail.id
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.procAPI}vendor/catalogue/detail/${catalogueId}`)
          .then(response => {
            context.commit('setOtherSupplierCatalogue', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    spesificationDetail(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.procAPI}vendor/catalogue_spesification/list?offset=${payload[1]}&limit=${payload[2]}&keyword=${payload[3]}&vendor_catalogue_id=${payload[0]}&mode=public`
          )
          .then(response => {
            context.commit('setSpesificationType', response)
            context.commit('setSpesificationDetail', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    brochureDownload(context, payload) {
      axios({
        url: `${context.state.uploadAPI}download/vendor_catalogue/brochure/${payload}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', payload)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    },
    vendorDetail(context, payload) {
      axios
        .get(`${context.state.procAPI}vendor/detail/${payload}`)
        .then(response => {
          context.commit('setVendorDetail', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  getters: {
    getParamCatList(state) {
      return state.param
    },
    getLoadCompare(state) {
      return state.isCompareLoading
    },
    getCompareItems(state) {
      return state.compareItems
    },
    getProductCompareStat(state) {
      return state.productCompareStatus
    },
    getCatalogueList(state) {
      return state.catalogueList
    },
    getCatalogueDetail(state) {
      return state.catalogueDetail
    },
    getOtherSupplier(state) {
      return state.otherSupplier
    },
    getSpesificationDetail(state) {
      return state.spesificationDetail
    },
    getSpecType(state) {
      return state.spesification_type
    },
    getCoordinate(state) {
      return state.coordinate
    },
    getVendorDetail(state) {
      return state.vendorDetail
    },
    getSpecForCompare(state) {
      return state.specForCompare
    }
  }
}
