// import axios from "axios";
import buildType from '../../../services/buildType'
export default {
  state: {
    eSanqua: buildType.apiURL('esanqua'),
    loanDocId: null,
    loanDocNumber: ''
  },
  mutations: {
    setLoaningDocId(state, payload) {
      state.loanDocId = payload
    },
    setLoanDocNumber(state, payload) {
      state.loanDocNumber = payload
    }
  },
  actions: {},
  getters: {
    getLoaningDocId(state) {
      return state.loanDocId
    },
    getLoanDocNumber(state) {
      return state.loanDocNumber
    }
  }
}
