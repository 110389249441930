import ApiService from './apiService'
import UrlConfig from './urlConfig'
const type = process.env.VUE_APP_BUILD_TYPE
class BuildType {
  apiURL(api) {
    switch (type) {
      case 'domain':
        return ApiService.domain(api)
      case 'production':
        return ApiService.production(api)
      case 'preproduction':
        return ApiService.preproduction(api)
      case 'development':
        return ApiService.development(api)
      case 'staging':
        return ApiService.staging(api)
      default:
        console.log('BOOOM')
    }
  }

  urlConfig(name) {
    switch (type) {
      case 'preproduction':
        return UrlConfig.preproduction(name)
      case 'development':
        return UrlConfig.development(name)
      case 'staging':
        return UrlConfig.staging(name)
      default:
        console.log('BOOOM')
    }
  }
}

export default new BuildType()
