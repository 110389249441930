import axios from 'axios'
import Swal from 'sweetalert2'
import router from '../../../router/index'
import buildType from '../../../services/buildType'
import store from '@/store'
export default {
  state: {
    // id: process.env.VUE_APP_BUILD_TYPE === 'development' ? 13 : 4,
    id: process.env.VUE_APP_BUILD_TYPE === 'development' ? 4 : 4,
    oAuth: buildType.apiURL('oauth'),
    login: false,
    token: localStorage.getItem('token') || null,
    userProfile: null,
    loginPayroll: {
      generate: false,
      additional: false,
      tab: false,
      access_token: null
    }
  },
  mutations: {
    setLogin(state) {
      state.login = true
    },
    setLogout(state) {
      state.login = false
    },
    setToken(state, payload) {
      state.token = payload
    },
    setLoginUserData(state, payload) {
      console.log(payload)
      if (payload === null) {
        state.userProfile = payload
      } else {
        state.userProfile = {
          employee: payload.employee,
          employee_id: payload.employee_id,
          level: payload.level,
          sanqua_company_id: payload.sanqua_company_id,
          sanqua_company_name: payload.sanqua_company_name,
          user_id: payload.user_id,
          user_type: payload.user_type,
          username: payload.username,
          vendor_id: payload.vendor_id,
          notification_via_fcm: payload.notification_via_fcm,
          notification_via_email: payload.notification_via_email,
          notification_via_wa: payload.notification_via_wa,
          notification_via_telegram: payload.notification_via_telegram
        }
      }
    },
    setLoginPayroll(state, payload) {
      // state.loginPayroll.generate = true
      state.loginPayroll.access_token = payload
    },
    setLoginPayrollAdditional(state) {
      state.loginPayroll.additional = true
    },
    setLoginPayrollTab(state) {
      state.loginPayroll.tab = true
    }
  },
  actions: {
    tokenValidation(context, payload) {
      const code = {
        code: payload
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.oAuth}user/verify_forgot_password`, code)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    verifyData(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.oAuth}user/verify_token?token=${payload}&method=conventional`
          )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    changePasswordNow(context, payload) {
      const newForm = {
        email: payload[0].email,
        old_password: payload[0].old_password,
        new_password: payload[0].confirm_new_password
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.oAuth}user/logged_change_password`, newForm)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },
    adminLogin(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.oAuth}user/login`, payload)
          .then((response) => {
            if (response.data.status_code === '-99') {
            } else {
              context.commit('setLogin')
              context.commit('setToken', response.data.token)
              localStorage.setItem('token', response.data.token)
              localStorage.setItem('refresh_token', response.data.refresh_token)
              context.commit('setLoginUserData', response.data)
              store.dispatch('setWebFcmToken')
              router.push('/')
            }
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    emailForgot(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.oAuth}user/forgot_password`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    logout(context) {
      if (context.state.login === true) {
        localStorage.removeItem('token')
        localStorage.removeItem('vuex')
        localStorage.removeItem('refresh_token')
        context.commit('setLogout')
        context.commit('setLoginUserData', null)
        router.push('/login')
        window.location.reload()
      }
    },
    checkToken(context, payload) {
      // if (payload.data.token_data !== undefined) {
      //   if (payload.data.token_data.status_code === '-99') {
      //     // return context.dispatch('logout')
      //     context.dispatch('refreshToken')
      //   }
      // }
    },
    async refreshToken(context) {
      // const instance = axios.create()
      // const payload = {
      //   token: context.state.token,
      //   refresh_token: localStorage.getItem('refresh_token'),
      //   user_id: context.state.userProfile.user_id
      // }
      // console.log('REFRESH TOKEN EXPIRED', payload)
      // await instance
      //   .post(`${context.state.oAuth}user/refresh_token`, payload)
      //   .then(res => {
      //     console.log(res)
      //     if (res.data.status_code === '00') {
      //       localStorage.setItem('token', res.data.new_access_token)
      //       localStorage.setItem('refresh_token', res.data.refresh_token)
      //       context.commit('setToken', res.data.new_access_token)
      //       //   window.location.reload()
      //     } else {
      //       console.log(res.data.status_msg)
      //       context.dispatch('logout')
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err)
      //     // this.logout()
      //     return null
      //   })
    },

    interceptorRequest(context) {
      axios.interceptors.request.use(
        function (config) {
          config.headers['x-method'] = 'conventional'
          config.headers['x-token'] = `${context.state.token}`
          config.headers['x-application-id'] = context.state.id
          config.headers['x-device'] = 'web'
          config.headers['x-device-id'] = 'browser'
          return config
        },
        function (error) {
          console.log('INTERCEPTOR HEADER ERROR', error)
          return Promise.reject(error)
        }
      )
    },
    interceptorResponse(context) {
      return new Promise((resolve, reject) => {
        axios.interceptors.response.use(
          function (response) {
            console.log('INTERCEPTOR RESPONSE', response)
            let code = ''
            let msg = ''
            if (response.data.status_code === '-99') {
              if (response.data.status_msg !== 'Data not found') {
                code = '-99'
                msg = response.data.status_msg
              }
            }
            if (response.data.status_code === '00') {
              if (response.data.token_data !== undefined) {
                if (response.data.token_data.status_code === '-99') {
                  code = '-99'
                  msg = response.data.token_data.status_msg
                }
              }
            }
            if (code === '-99') {
              console.log(msg)
              // Swal.fire(
              //   'Content Error',
              //   `${msg},\nrefresh the page or contact an admin`
              // )
            }
            // context.dispatch('checkToken', response)
            resolve(response)
            return response
          },

          function (error) {
            console.log('INTERCEPTOR RESPONSE ERROR', error)
            reject(error)
            if (error.response.status === 400) {
              Swal.fire(
                'Notice!',
                'Something went worng, Please contact an admin',
                'error'
              )
              // localStorage.removeItem('token')
              // context.commit('setLogout')
              // router.push('/login')
            }
            return Promise.reject(error)
          }
        )
      })
    }
  },
  getters: {
    getLogin(state) {
      return state.login
    },
    getToken(state) {
      return state.token
    },
    getUserProfile(state) {
      return state.userProfile
    },
    getLoginPayroll(state) {
      return state.loginPayroll.access_token
    },
    getLoginPayrollAdditional(state) {
      return state.loginPayroll.additional
    },
    getLoginPayrollTab(state) {
      return state.loginPayroll.tab
    }
  }
}
