import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import underscore from 'vue-underscore'
import JsonCSV from 'vue-json-csv'
import firebase from '@/plugins/firebase'

import { BootstrapVue, CarouselPlugin } from 'bootstrap-vue'

import VueSweetalert2 from 'vue-sweetalert2'
import VuePdfApp from 'vue-pdf-app'
import VueQRCodeComponent from 'vue-qrcode-component'
// import VueBarcode from '@chenfengyuan/vue-barcode'
// import VueZxingScanner from 'vue-zxing-scanner'

import 'bootstrap/dist/css/bootstrap.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import { VueEditor } from 'vue2-editor'

require('dotenv').config()
Vue.component('vue-pdf-app', VuePdfApp)
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueSweetalert2)
Vue.use(underscore)
Vue.use(BootstrapVue)
Vue.use(CarouselPlugin)
Vue.use(require('vue-moment'))
Vue.component('downloadCsv', JsonCSV)
Vue.component('vue-editor', VueEditor)
// Vue.component(VueBarcode.name, VueBarcode)
// Vue.use(VueZxingScanner)

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_KEY,
    libraries: 'places'
  }
})

new Vue({
  router,
  store,
  vuetify,
  firebase,
  render: h => h(App)
}).$mount('#app')
