export default {
  state: {
    detail: null,
    urlQuery: null
  },
  mutations: {
    setPermissionDetail(state, payload) {
      state.detail = payload
    },
    setPermissionPageQuery(state, payload) {
      state.urlQuery = payload
    }
  },
  actions: {},
  getters: {
    getPermissionDetail(state) {
      return state.detail
    },
    getPermissionPageQuery(state) {
      return state.urlQuery
    }
  }
}
