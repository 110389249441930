import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  state: {
    asset: buildType.apiURL('asset'),
    asset2: buildType.apiURL('asset2'),
    eSanqua: buildType.apiURL('esanqua'),
    items: null,
    assetCategory: [],
    assetLotCompanyId: null,
    parameter: {
      plant_id: null
    }
  },
  mutations: {
    setParameter(state, payload) {
      state.parameter.plant_id = payload
    },
    setParamAsset(state, payload) {
      state.items = payload
    },
    setDropdownAssetCategory(state, payload) {
      state.assetCategory = payload
    },
    setAssetLotCompanyId(state, payload) {
      state.assetLotCompanyId = payload
    }
  },
  actions: {
    dropdownAssetCategory(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.asset}master/assetcategories/dropdown?keyword=`)
          .then(response => {
            resolve(response.data.data)
            context.commit('setDropdownAssetCategory', response.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {
    getParamAsset(state) {
      return state.items
    },
    getDropdownAssetCategory(state) {
      return state.assetCategory
    }
  }
}
