<template>
  <div class="fluid" id="home">
    <v-main class="justify-content-center align-items-center fill-height">
      <div v-if="!getIsLoading" style="height:85vh; overflow:auto;">
        <v-row v-if="permission.read_perm === 1" class="home-row">
          <!-- <v-col
            cols="12"
            class="d-flex justify-content-between align-center flex-wrap"
            style="padding: 20px 15px;max-height:90vh;"
          > -->
          <!-- <v-col :key="i" cols="12" md="6" style="padding: 20px 0;"> -->
          <v-col
            cols="6"
            md="3"
            v-for="(item, i) in apps"
            :key="i"
            class="home-tab-container"
          >
            <!-- :hidden="item.sort === 'HRS' && build !== 'development'" -->
            <!-- <div v-for="(item, i) in apps" :key="i" class="home-tab-container"> -->
            <div style="width:90px; margin:auto;">
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 3 : 2"
                  :class="({ 'on-hover': hover }, 'mx-auto', 'home-tab')"
                  @click="routerPush(item.name, item.id, item.path)"
                >
                  <v-list-item style="padding:10px;">
                    <v-list-item-avatar
                      tile
                      size="60"
                      :color="item.color"
                      style="margin:auto;box-shadow:inset 2px 2px 6px rgba(0,0,0,0.8);"
                    >
                      <v-img
                        v-if="item.asset"
                        contain
                        :src="item.icon"
                        :style="
                          `height:${item.height};width:${item.width}; filter: invert(50%) sepia(50%) saturate(50%) hue-rotate(90deg) brightness(200%) contrast(200%) drop-shadow(5px 5px 2px rgb(0 0 0 / 0.5));`
                        "
                      />
                      <v-icon
                        v-else
                        color="white"
                        x-large
                        style="filter: drop-shadow(8px 5px 2px rgb(0 0 0 / 0.5))"
                      >
                        {{ item.icon }}
                      </v-icon>
                      <!-- <v-img v-else :src="item.icon"/></v-img> -->
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </v-hover>
            </div>
            <p class="home-tab-alias">
              {{ item.alias }}
            </p>
            <!-- </div> -->
          </v-col>
          <!-- </v-col> -->
        </v-row>
        <AccessDenied v-if="permission.read_perm === 0" />

        <div style="position:fixed; bottom:10px; right:0;">
          <Scanner />
        </div>
      </div>
      <div v-else style="height:91vh;">
        <Loader />
      </div>
    </v-main>
  </div>
</template>
<script>
import Loader from '../../components/common/loader/CircularProgress'
import AccessDenied from '../../components/common/accessDenied/accessDenied'
import Scanner from './components/Scanner.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
// const NodeRSA = require('node-rsa')
export default {
  data: () => ({
    build: process.env.VUE_APP_BUILD_TYPE,
    menu_key: 26,
    permission: {},
    application: {
      // id: process.env.VUE_APP_BUILD_TYPE === 'development' ? 13 : 4,
      id: process.env.VUE_APP_BUILD_TYPE === 'development' ? 4 : 4,
      name: 'eSanqua'
    },
    apps: [
      {
        name: 'DOCUMENT MANAGEMENT SYSTEM',
        alias: 'Dokumen Legalitas',
        code: 'DMS',
        id: 14,
        path: '/archive-management',
        icon: 'mdi-scale-balance',
        color: 'indigo'
      },
      {
        name: 'ASSET MANAGEMENT SYSTEM',
        alias: 'Aset',
        code: 'AMS',
        id: 18,
        path: '/asset-management',
        icon: 'mdi-cash-multiple',
        color: '#827717'
      },
      {
        name: 'eCATALOGUE',
        alias: 'Katalog',
        code: 'CAT',
        id: 8,
        path: '/e-catalogue/catalogue-list',
        icon: 'mdi-cards',
        color: 'purple'
      },
      // {
      //   name: 'ePROCUREMENT',
      //   alias: 'Procurement',
      //   code: 'PRC',
      //   id: 2,
      //   path: 'https://bo-esanqua.sanquawater.co.id/',
      //   icon: 'mdi-dolly',
      //   color: '#006064'
      // },
      {
        name: 'FAT UTILITY',
        alias: 'Payment Request',
        code: 'FAT',
        id: 17,
        path: 'http://10.10.20.6:7351/',
        icon: 'mdi-credit-card-settings-outline',
        color: '#33691E'
      },
      {
        name: 'ROOM BOOKING SYSTEM',
        alias: 'Ruang Meeting',
        code: 'RBS',
        id: 9,
        path: '/meeting-activity/calendar-view',
        icon: 'mdi-google-classroom',
        color: '#263238'
      },
      // {
      //   name: 'SOP',
      //   alias: 'SOP',
      //   code: 'SOP',
      //   id: 14,
      //   path: '/archive-management/sop',
      //   icon: 'mdi-book-open-variant',
      //   color: '#6D4C41'
      // },
      // {
      //   name: 'REPO',
      //   alias: 'Repository',
      //   code: 'REP',
      //   id: 14,
      //   path: '/archive-management/repository',
      //   icon: 'mdi-source-repository-multiple',
      //   color: '#00897B'
      // },
      {
        name: 'DOKUMEN INTERNAL',
        alias: 'Dokumen Internal',
        code: 'DKI',
        id: 14,
        path: '/archive-management/internal-document',
        icon: 'mdi-file-document-multiple',
        color: '#00897B'
      },
      {
        name: 'HR SYSTEM',
        alias: 'HR System',
        code: 'HRS',
        id: 21,
        path: '/hr/dashboard',
        icon: 'mdi-target-account',
        color: '#F9A825'
      },
      {
        name: 'Expedition',
        alias: 'Ekspedisi',
        code: 'EXP',
        id: 19,
        path: '/expedition',
        icon: require('../../assets/image/speedtruck.png'),
        asset: true,
        color: '#664f4d',
        height: '25px',
        width: '30px'
      },
      {
        name: 'HELPDESK',
        alias: 'Helpdesk',
        code: 'HDK',
        id: 4,
        path: `https://helpdesk.sanquawater.co.id/login_esanqua?token=${localStorage.getItem(
          'token'
        )}`,
        icon: 'mdi-chat-question-outline',
        color: '#899878'
      },
      {
        name: 'MONITORING MACHINE SYSTEM',
        alias: 'Monitoring System',
        code: 'MMS',
        id: 22,
        path: '/mms',
        icon: 'mdi-monitor-dashboard',
        color: '#8f2d56'
      }
      // {
      //   name: 'GRAFANA',
      //   alias: 'Grafana',
      //   code: 'GF',
      //   id: 4,
      //   path: '',
      //   icon: require('../../assets/image/grafana-icon.png'),
      //   asset: true,
      //   color: '#32322c',
      //   height: '50px',
      //   width: '50px'
      // }
    ]
  }),
  components: {
    Loader,
    AccessDenied,
    Scanner
  },
  async created() {
    if (!this.getLogin) {
      this.$router.push('/login')
    }
    this.setTabs([])
    await this.sortApps()
    this.setIsLoading(true)
    const arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            this.drawerSet()

            this.loadNotification()
            this.loadPermissionApprovalNeeds()
            this.loadLeaveApprovalNeeds()
            setTimeout(() => {
              this.setIsLoading(false)
            }, 3000)
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
              setTimeout(() => {
                this.setIsLoading(false)
              }, 3000)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          this.setIsLoading(false)
        }, 3000)
      }
    }
  },
  computed: {
    ...mapGetters(['getLogin', 'getUserProfile', 'getIsLoading'])
  },
  methods: {
    ...mapActions([
      'menu_access',
      'loadNotification',
      'loadPermissionApprovalNeeds',
      'loadLeaveApprovalNeeds'
    ]),
    ...mapMutations(['setTabs', 'setIsLoading', 'setPermission', 'setDrawer']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            title:
              pModalType === 'success'
                ? '<strong>Success</strong>'
                : `<strong>${pModalType}</strong>`,
            icon: pModalType,
            html: pStatusMsg,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No',
            timer: 5000
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    async sortApps() {
      this.apps.sort((a, b) => {
        const fa = a.alias.toLowerCase()
        const fb = b.alias.toLowerCase()

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
    },
    drawerSet() {
      this.setDrawer([
        {
          title: 'Open Profile',
          path: `/hr/employee/detail/${this.getUserProfile.employee_id}`,
          icon: 'mdi-account-cog'
        },
        {
          title: 'Change password',
          path: '/change-password',
          icon: 'mdi-key-change'
        }
      ])
    },
    home() {
      this.$router.push('/')
    },
    checkAppID(id) {
      const application = this.getUserProfile.level
      const result = application.find(
        ({ application }) => application.id === id || application.id === 1
      )
      return result
    },
    routerPush(name, id, path) {
      const msg = 'You cannot access this feature'
      switch (name) {
        case 'DOCUMENT MANAGEMENT SYSTEM':
          if (this.checkAppID(id) !== undefined) {
            this.$router.push(path)
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
        case 'ASSET MANAGEMENT SYSTEM':
          if (this.checkAppID(id) !== undefined) {
            this.$router.push(path)
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
        case 'eCATALOGUE':
          if (this.checkAppID(id) !== undefined) {
            this.$router.push(path)
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
        // case 'ePROCUREMENT':
        //   if (this.checkAppID(id) !== undefined) {
        //     window.open(path, '_blank')
        //   } else {
        //     this.showMsgDialog('warning', msg)
        //   }
        //   break
        case 'FAT UTILITY':
          if (this.checkAppID(id) !== undefined) {
            window.open(path, '_blank')
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
        case 'ROOM BOOKING SYSTEM':
          if (this.checkAppID(id) !== undefined) {
            this.$router.push(path)
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
        // case 'SOP':
        //   if (this.checkAppID(id) !== undefined) {
        //     this.$router.push(path)
        //   } else {
        //     this.showMsgDialog('warning', msg)
        //   }
        //   break
        // case 'REPO':
        //   if (this.checkAppID(id) !== undefined) {
        //     this.$router.push(path)
        //   } else {
        //     this.showMsgDialog('warning', msg)
        //   }
        //   break
        case 'DOKUMEN INTERNAL':
          if (this.checkAppID(id) !== undefined) {
            this.$router.push(path)
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
        case 'HR SYSTEM':
          if (this.checkAppID(id) !== undefined) {
            this.$router.push(path)
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
        case 'Expedition':
          if (this.checkAppID(id) !== undefined) {
            this.$router.push(path)
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
        case 'HELPDESK':
          if (this.checkAppID(id) !== undefined) {
            window.open(path, '_blank')
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
        case 'MONITORING MACHINE SYSTEM':
          if (this.checkAppID(id) !== undefined) {
            if (this.build === 'development' || this.build === 'staging') {
              this.$router.push(path)
            } else {
              if (
                this.getUserProfile.level.find(
                  ({ application }) => application.id === 1
                ) !== undefined
              ) {
                this.$router.push(path)
              } else {
                this.showMsgDialog(
                  'warning',
                  'Maaf Anda belum dapat menggunakan menu ini'
                )
              }
            }
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
        case 'GRAFANA':
          if (this.checkAppID(id) !== undefined) {
            // const url =
            //   'http://mms-dev.sanquawater.co.id/login/generic_oauth?state=cSEqSRcKZP84GgHlPo7eS6BrID-ZXIFai7Ow9Vlr2Y0=&code=f7f08d7cad83f50cc51d0ff74de612f1'
            // window.open(url, '_blank')
            this.showMsgDialog('warning', 'COOMING SOON')
          } else {
            this.showMsgDialog('warning', msg)
          }
          break
      }
    }
  }
}
</script>

<style lang="scss">
.v-card {
  transition: opacity 0.3s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 1;
}
#home {
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 2;
  // min-h2eight: 125vh;

  main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 91.5vh;
    padding: 20px;
    .home-row {
      width: 70%;
      margin: auto;
      padding: 10px 0;
      .home-tab-container {
        // margin-left: 60px;
        // margin-right: 60px;
        padding: 0 20px;
        height: 150px;
        &:hover .home-tab-alias {
          text-shadow: 2px 2px 3px #dddddd;
        }
        .home-tab {
          cursor: pointer;
          // width: 90%;
          .home-tab-title {
            font-size: 20px;
          }
        }
        .home-tab-alias {
          margin: auto;
          text-align: center;
          width: 90px;
          font-size: 16px;
          margin-top: 5px;
          // text-shadow: 2px 1.5px 3px #aaaaaa;
        }
      }
    }
  }
}
</style>
