class UrlConfig {
  staging(access) {
    switch (access) {
      case 'tracking':
        return 'http://10.10.20.6:7355/expedition/'
    }
  }

  development(access) {
    switch (access) {
      case 'tracking':
        return 'http://10.10.20.6:7357/expedition/'
    }
  }

  preproduction(access) {
    switch (access) {
      case 'tracking':
        return 'http://10.10.20.6:8085/expedition/'
    }
  }
}

export default new UrlConfig()
