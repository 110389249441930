export default {
  state: {
    urlQuery: null
  },
  mutations: {
    setGrPageQuery(state, payload) {
      state.urlQuery = payload
    },
    setOutstandingPageQuery(state, payload) {
      state.urlQuery = payload
    }
  },
  actions: {},
  getters: {
    getGrPageQuery(state) {
      return state.urlQuery
    },
    getOutstandingPageQuery(state) {
      return state.urlQuery
    }
  }
}
