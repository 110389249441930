export default {
  state: {
    urlQuery: null
    // isLoginPayroll: false
  },
  mutations: {
    setEmployeePageQuery(state, payload) {
      state.urlQuery = payload
    }
    // setLoginPayroll(state, payload) {
    //   state.isLoginPayroll = payload
    // }
  },
  actions: {},
  getters: {
    getEmployeePageQuery(state) {
      return state.urlQuery
    }
    // getLoginPayroll(state) {
    //   return state.isLoginPayroll
    // }
  }
}
