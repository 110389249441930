export default {
  state: {
    oeeId: null,
    savedParams: {
      page: 1,
      itemsPerPage: 10,
      schedule_id: null,
      machine_id: null,
      start_date: '',
      end_date: '',
      keyword: '',
      offset: 0,
      limit: 10,
      sortBy: 'date',
      sortType: 'desc'
    }
  },
  mutations: {
    setOeeId(state, payload) {
      state.oeeId = payload
    },
    setParams(state, payload) {
      console.log(payload)
      state.savedParams.page = payload.page
      state.savedParams.itemsPerPage = payload.itemsPerPage
      state.savedParams.schedule_id = payload.schedule_id
      state.savedParams.machine_id = payload.machine_id
      state.savedParams.start_date = payload.start_date
      state.savedParams.end_date = payload.end_date
      state.savedParams.keyword = payload.keyword
      state.savedParams.limit = payload.limit
      state.savedParams.offset = payload.offset
      state.savedParams.sortBy = payload.sortBy
      state.savedParams.sortType = payload.sortType
    }
  },
  getters: {
    getOeeId(state) {
      return state.oeeId
    },
    getSavedParams(state) {
      return state.savedParams
    }
  }
}
