import axios from 'axios'
import buildType from '../../../services/buildType'
import store from '@/store'
import { getMessaging, getToken } from 'firebase/messaging'
export default {
  state: {
    hrsApi: buildType.apiURL('hrs'),
    oAuth: buildType.apiURL('oauth'),
    esanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    fcmToken: '',
    // notification: 0,
    notification: {
      totalData: 0,
      result: [],
      totalUnread: 0,
      loading: false
    },
    permission: {
      result: [],
      totalData: 0,
      loading: false
    },
    leave: {
      result: [],
      totalData: 0,
      loading: false
    },
    fpb: {
      result: [],
      totalData: 0,
      loading: false
    }
  },
  mutations: {
    setFcmToken(state, payload) {
      state.fcmToken = payload
    },
    setNotificationResult(state, payload) {
      state.notification.result = payload.data
      state.notification.totalData = payload.total_record

      state.notification.totalUnread = 0
      for (let i = 0; i < payload.data.length; i++) {
        if (payload.data[i].status === 0) {
          ++state.notification.totalUnread
        }
      }

      state.notification.loading = false
    },
    setPermissionApprovalNeedsResult(state, payload) {
      state.permission.result = payload.data
      state.permission.totalData = payload.total_record
      state.permission.loading = false
    },
    setLeaveApprovalNeedsResult(state, payload) {
      state.leave.result = payload.data
      state.leave.totalData = payload.total_record
      state.leave.loading = false
    },
    setFpbApprovalNeedsResult(state, payload) {
      state.fpb.result = payload.data
      state.fpb.totalData = payload.total_record
      state.fpb.loading = false
    }
  },
  actions: {
    async loadNotification(context, payload) {
      console.log('hereee load notif store >>>>>', payload)
      var paramStatus = ''
      var paramModule = ''
      var paramSearch = ''
      if (payload !== undefined) {
        paramSearch = payload.keyword
        if (payload.document_status !== undefined) {
          if (payload.document_status !== null) {
            paramStatus = `&document_status=${payload.document_status}`
          }
        }
        if (payload.module !== undefined) {
          if (payload.module !== null) {
            paramModule = `&module=${payload.module}`
          }
        }
      }
      context.state.notification.loading = true
      // return await new Promise((resolve, reject) => {
      const url = `${context.state.esanqua}notification/list?keyword=${paramSearch}&offset=0&limit=all&application_code=&channel=1${paramStatus}${paramModule}`
      console.log('url >>>>', url)
      await axios
        .get(url)
        .then((res) => {
          console.log('res >>>>>>>', res)
          setTimeout(() => {
            context.commit('setNotificationResult', res.data)
          }, 1000)
        })
        .catch((err) => {
          console.log(err)
          context.state.notification.loading = false
          // reject(err)
        })
      // })
    },
    async loadPermissionApprovalNeeds(context, payload) {
      context.state.permission.loading = true
      // return await new Promise((resolve, reject) => {
      await axios
        .get(
          `${context.state.hrsApi}employee/permission/list?keyword=&offset=0&limit=10000&mode=2&status_permission=1`
        )
        .then((res) => {
          setTimeout(() => {
            context.commit('setPermissionApprovalNeedsResult', res.data)
          }, 1000)
        })
        .catch((err) => {
          console.log(err)
          context.state.permission.loading = false
          // reject(err)
        })
      // })
    },
    async loadLeaveApprovalNeeds(context, payload) {
      context.state.leave.loading = true
      // return await new Promise((resolve, reject) => {
      await axios
        .get(
          `${context.state.hrsApi}employee/leave/list?keyword=&offset=0&limit=10000&mode=2&status_leave=1&employee_id=`
        )
        .then((res) => {
          setTimeout(() => {
            context.commit('setLeaveApprovalNeedsResult', res.data)
          }, 1000)
        })
        .catch((err) => {
          console.log(err)
          context.state.leave.loading = false
          // reject(err)
        })
      // })
    },
    async loadFpbApprovalNeeds(context, payload) {
      context.state.fpb.loading = true
      await axios
        .get(
          // `${context.state.hrsApi}employee/leave/list?keyword=&offset=0&limit=10000&mode=2&status_leave=1&employee_id=`
        )
        .then((res) => {
          setTimeout(() => {
            context.commit('setFpbApprovalNeedsResult', res.data)
          }, 1000)
        })
        .catch((err) => {
          console.log(err)
          context.state.fpb.loading = false
          // reject(err)
        })
    },
    async setWebFcmToken(context) {
      const messaging = getMessaging()
      getToken(messaging, { vapidKey: process.env.VUE_APP_FCM_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            console.log('FCM TOKEN>>>>>>', currentToken)
            axios
              .post(
                `${context.state.oAuth}user/fcm_token`,
                {
                  fcm_token_web: currentToken
                },
                {
                  headers: {
                    'x-token': store.state.auth.token,
                    'x-method': 'conventional',
                    'x-device-id': 'browser',
                    'x-device': 'web'
                  }
                }
              )
              .then(() => {})
              .catch((error) => {
                console.log(error)
              })
          } else {
            // Show permission request UI
            console.log(
              'No registration token available. Request permission to generate one.'
            )
            // ...
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
          // ...
        })
    }
  },
  getters: {
    getTotalMessage(state) {
      let total = 0
      total = state.notification.totalUnread
      //  +
      // state.permission.totalData +
      // state.leave.totalData
      return total
    },
    getNotification(state) {
      return state.notification
    },
    getPermissionApprovalNeeds(state) {
      return state.permission
    },
    getLeaveApprovalNeeds(state) {
      return state.leave
    },
    getFpbApprovalNeeds(state) {
      return state.fpb
    }
  }
}
