export default {
  state: {
    urlQuery: null
  },
  mutations: {
    setPjcaPageQuery(state, payload) {
      state.urlQuery = payload
    }
  },
  actions: {},
  getters: {
    getPjcaPageQuery(state) {
      return state.urlQuery
    }
  }
}
