import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  state: {
    oAuth: buildType.apiURL('oauth'),
    eSanqua: buildType.apiURL('esanqua'),
    roomBookingList: [],
    bookingDetail: null,
    roomDropdown: [],
    // buildingDropdown: [],
    roomBookingId: ''
  },
  mutations: {
    setRoomBookingId(state, payload) {
      state.roomBookingId = payload.id
    },
    setRoomBookingList(state, payload) {
      state.roomBookingList = []
      const response = payload.data
      if (response !== undefined) {
        state.totalBookingItems = payload.total_record
        for (let i = 0; i < response.length; i++) {
          // if (
          //   this.state.auth.userProfile.employee.company.name ===
          //   response[i].company_name
          // ) {
          state.roomBookingList.push({
            booking_no: response[i].booking_no,
            company_name: response[i].company_name,
            created_at: response[i].created_at,
            created_by_name: response[i].created_by_name,
            department_name: response[i].department_name,
            description: response[i].description,
            employee_name: response[i].employee_name,
            end_use: response[i].end_use,
            id: response[i].id,
            meeting_type: response[i].meeting_type,
            meeting_type_other: response[i].meeting_type_other,
            number_of_attendance: response[i].number_of_attendance,
            room: response[i].room,
            start_use: response[i].start_use,
            status: response[i].status,
            updated_at: response[i].updated_at,
            updated_by_name: response[i].updated_by_name
          })
          // }
        }
      } else {
        state.totalBookingItems = 1
        state.roomBookingList = []
      }
    },
    setBookingDetail(state, payload) {
      state.bookingDetail = payload.data
    },
    setRoomDropdown(state, payload) {
      const newArr = []
      if (payload[1].sanqua_company_id !== 2) {
        for (let i = 0; i < payload[0].data.length; i++) {
          if (
            payload[0].data[i].id === 1 ||
            payload[0].data[i].id === 2 ||
            payload[0].data[i].id === 3 ||
            payload[0].data[i].id === 4 ||
            payload[0].data[i].id === 5 ||
            payload[0].data[i].id === 10
          ) {
            newArr.push(payload[0].data[i])
          }
        }
      } else {
        for (let i = 0; i < payload[0].data.length; i++) {
          newArr.push(payload[0].data[i])
        }
      }
      // for (let i = 0; i < payload.data.length; i++) {
      //   newArr.push(payload.data[i])
      // }
      state.roomDropdown = newArr
    }

    // setBuildingDropdown(state, payload) {
    //   state.buildingDropdown = payload.data
    // }
  },
  actions: {
    documentAuthority(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.oAuth}approval_matrix/document/allow_user?document_id=${payload.approval_matrix[0].document_id}&application_id=9`
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    approvalMatrixDocumentList(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.oAuth}approval_matrix/document/list?limit=100&offset=0&keyword=&application_id=9&table_name=${payload.approval_matrix[0].table_name}&document_id=${payload.approval_matrix[0].document_id}`
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    roomBookingList(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.eSanqua}roombooking/list?keyword=${
              payload.keyword
            }&offset=${payload.offset}&limit=${payload.limit}${
              payload.status !== undefined ? '&status=' + payload.status : ''
            }&room_id=${payload.room_id}`
          )
          .then(response => {
            context.commit('setRoomBookingList', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    bookingDetail(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.eSanqua}roombooking/detail/${context.state.roomBookingId}`
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    roomDropdown(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.eSanqua}room/drop_down`)
          .then(response => {
            context.commit('setRoomDropdown', [response.data, payload])
            resolve(response.data)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    // buildingDropdown(context) {
    //   axios
    //     .get(`${context.state.eSanqua}building/drop_down`)
    //     .then(response => {
    //       context.commit('setBuildingDropdown', response.data)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // },
    requestRoom(context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.eSanqua}roombooking/save`, payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    confirmRoomBooking(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.eSanqua}roombooking/confirm`, payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    confirmRoomBookingViaEmail(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.eSanqua}roombooking/confirm_via_email/${payload}`
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    cancelRoomBooking(context, payload) {
      const id = {
        id: payload.id
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.eSanqua}roombooking/cancel`, id)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchMatrix(context, payload) {
      const form = {
        act: 'fetch_matrix',
        document_id: payload.id,
        document_no: payload.booking_no,
        application_id: parseInt(payload.application_id),
        table_name: payload.table_name
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.oAuth}approval_matrix/document/save`, form)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    checkRoomSchedule(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.eSanqua}roombooking/qrcode/list/${payload}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    roomBookingListCalendar(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.eSanqua}roombooking/list?keyword=${payload.keyword}&offset=${payload.offset}&mode=${payload.mode}&room_id=${payload.room_id}`
          )
          .then(response => {
            console.log(response)
            context.commit('setRoomBookingList', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {
    getRoomBookingList(state) {
      return state.roomBookingList
    },
    getBookingDetail(state) {
      return state.bookingDetail
    },
    getRoomDropdown(state) {
      return state.roomDropdown
    }
    // getBuildingDropdown(state) {
    //   return state.buildingDropdown
    // }
  }
}
