// import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  state: {
    procAPI: buildType.apiURL('proc'),
    // savedData: [],
    projectItems: null,
    detail: null
    // fpbId: null,
    // fpbSelectedList: null,
    // fpbSelectedListIndex: 0
  },
  mutations: {
    setProjectItems(state, payload) {
      state.projectItems = payload
    },
    setProjectDetail(state, payload) {
      state.detail = payload
    }
    // setFpbId(state, payload) {
    //   state.fpbId = payload
    // },
    // setFpbList(state, payload) {
    //   state.fpbSelectedList = payload.list
    //   state.fpbSelectedListIndex = payload.index
    // }
  },
  actions: {},
  getters: {
    // getFpbId(state) {
    //   return state.fpbId
    // },
    getFpbProjectParam(state) {
      return state.projectItems
    },
    getProjectDetail(state) {
      return state.detail
    }
    // getFpbList(state) {
    //   return {
    //     fpbList: state.fpbSelectedList,
    //     selectedIndex: state.fpbSelectedListIndex
    //   }
    // }
  }
}
