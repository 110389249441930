// import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  state: {
    procAPI: buildType.apiURL('proc'),
    savedData: [],
    fpbItems: null,
    fpbId: null,
    fpbSelectedList: null,
    fpbSelectedListIndex: 0
  },
  mutations: {
    setCartList(state, payload) {
      if (
        state.savedData.find(
          (a) =>
            a.product.id === payload.product.id &&
            a.vendor.clear_id === payload.vendor.clear_id
        ) === undefined
      ) {
        const item = {
          // id: payload.id,
          product: payload.product,
          vendor: {
            clear_id: payload.vendor.clear_id,
            code: payload.vendor.code,
            name: payload.vendor.name
          },
          currency: payload.currency,
          merk: payload.merk,
          last_price: payload.last_price,
          uom_id: payload.uom_id,
          uom_name: payload.uom_name
        }
        Object.assign(item, {
          qty: 1,
          budget: 0,
          budget_edit: false,
          total_price: 0,
          reason: ''
        })
        state.savedData.push(item)
      } else {
        ++state.savedData.find(
          (a) =>
            a.product.id === payload.product.id &&
            a.vendor.clear_id === payload.vendor.clear_id
        ).qty
      }
      // const item = payload
    },
    setFpbItems(state, payload) {
      state.fpbItems = payload
    },
    setFpbId(state, payload) {
      state.fpbId = payload
    },
    setFpbList(state, payload) {
      state.fpbSelectedList = payload.list
      state.fpbSelectedListIndex = payload.index
    },
    loadTemporaryCart(state) {
      state.savedData = JSON.parse(localStorage.getItem('temporarycart'))
      setTimeout(() => {
        localStorage.removeItem('temporarycart')
        window.location.reload()
      }, 500)
    }
  },
  actions: {},
  getters: {
    getFpbId(state) {
      return state.fpbId
    },
    getFpbParam(state) {
      return state.fpbItems
    },
    getFpbList(state) {
      return {
        fpbList: state.fpbSelectedList,
        selectedIndex: state.fpbSelectedListIndex
      }
    }
  }
}
